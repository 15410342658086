import GATSBY_COMPILED_MDX from "/Users/eswan18/Develop/michaelswan.studio/works/watercolor/ethan-violin/index.mdx";
import React from "react";
import {graphql} from "gatsby";
import {BaseLayout} from "../../layouts";
import {titlecase, pluralize} from '../../utils/text';
import CollectionView from '../../components/collectionView';
import styled from 'styled-components';
const CenteredFlex = (styled.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
function MediumPage({data, pageContext}) {
  const medium = pageContext.frontmatter__medium;
  const works = data.allMdx.edges.map(node => node.node.frontmatter);
  const mediumWorks = works.filter(work => work.medium === medium);
  return React.createElement(BaseLayout, null, React.createElement(CenteredFlex, null, React.createElement("h1", null, titlecase(pluralize(medium)))), React.createElement(CenteredFlex, null, React.createElement(CollectionView, {
    works: mediumWorks
  })));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(MediumPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
query ($medium: String) {
  allMdx(filter: {frontmatter: {medium: {eq: $medium}}}) {
    edges {
      node {
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 400,
                placeholder: BLURRED,
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          medium
          title
          slug
        }
      }
    }
  }
}
`;
